<template>
  <v-container
    id="dataexport"
    fluid
    tag="section"
    class="grey lighten-4"
  >
    <!-- Dashboard Block Info' toolbar -->
    <v-toolbar
      style="z-index: 1"
      color="elevation-0"
      dense
    >
      <v-toolbar-title class="text-h2 grey--text text--darken-0 pr-8">
        {{ $store.state.main.af.title }} - {{ $t('monitoring.dstat') }}
      </v-toolbar-title>
      <v-spacer />

      <!-- Open and close InfoCard -->
      <v-btn
        v-if="myRole() === 'manager'"
        color="warning"
        fab
        dark
        @click="helptoolbar = !helptoolbar"
      >
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- Add Monitoring Tables block -->
    <v-card
      v-if="!helptoolbar"
      class="mt-0"
      color="elevation-0"
    >
      <v-row class="px-8 pt-4">
        <!-- List of dataset e.g. Sections -->
        <v-col
          cols="12"
          md="4"
          lg="3"
        >
          <v-select
            :items="dataSet"
            outlined
            dense
            item-text="label[0]"
            item-value="table[0]"
            prepend-inner-icon="mdi-table"
            :label="$t('analysis.select-dataset')"
            @change="dataSetSelected"
          />
        </v-col>
        <!-- List of questions e.g. variables for selected dataset -->
        <v-col
          cols="12"
          md="8"
          lg="9"
        >
          <v-autocomplete
            v-model="myQuestion"
            return-object
            :items="questions"
            :item-text="questionLabels.type"
            item-value="id"
            :prepend-icon="questionLabels.icon"
            :label="$t('analysis.select-vars')"
            outlined
            dense
            @input="questionSelected"
            @click:prepend="changeLabel"
          />
        </v-col>

        <!-- Descriptive Statistics part -->
        <v-row>
          <v-col
            cols="12"
            md="4"
            class="mt-0"
          >
            <v-simple-table
              class="ml-1"
            >
              <tbody>
                <tr
                  v-for="(stat, i) in dstat.data.stats"
                  :key="i"
                >
                  <td v-text="stat.type[0]" />
                  <td v-text="stat.value[0]" />
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-chart
              v-if="echart"
              class="chart"
              :option="bar"
            />
          </v-col>
        </v-row>
      </v-row>
      <validation-observer v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(mtSave)">
          <v-card-actions
            v-if="myRole() === 'manager' & echart"
            class="pb-4 px-8"
          >
            <validation-provider
              v-slot="{ errors }"
              :name="$t('mpref.mt-title')"
              rules="required"
            >
              <v-text-field
                v-model="mtTitle"
                outlined
                dense
                hide-details
                :error-messages="errors"
                :label="$t('mpref.mt-title')"
              />
              <v-spacer />
            </validation-provider>
            <v-spacer />
            <v-btn
              color="warning"
              dark
              @click="clearPTable()"
            >
              {{ $t('common.clear') }}
            </v-btn>
            <v-btn
              color="success"
              dark
              type="submit"
            >
              {{ $t('common.save') }}
            </v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
      <v-card-actions>
        <v-row class="pb-4 px-8">
          <v-spacer />
          <!-- Keep closed -->
          <v-checkbox
            v-model="dBInfo"
            :label="$t('dashboard.keep-closed')"
            class="py-0 my-0"
            hide-details
          />
        </v-row>
      </v-card-actions>
    </v-card>

    <monitor-stats-list ref="mtDStatistics" />

  </v-container>
</template>

<script>
  import { use } from 'echarts/core'
  import userAccess from '@/mixins/user-access'
  import dashboard from '@/mixins/dashboard'
  import MonitorStatsList from './components/MonitorStatsList.vue'

  import { CanvasRenderer } from 'echarts/renderers'
  import { BarChart } from 'echarts/charts'
  import {
    GridComponent,
    TooltipComponent,
    LegendComponent,
  } from 'echarts/components'
  import VChart, { THEME_KEY } from 'vue-echarts'
  use([
    CanvasRenderer,
    BarChart,
    GridComponent,
    TooltipComponent,
    LegendComponent,
  ])

  export default {
    name: 'App',
    components: {
      VChart,
      MonitorStatsList,
    },

    mixins: [
      userAccess,
      dashboard,
    ],
    provide: {
      [THEME_KEY]: 'light',
    },

    data () {
      return {
        helptoolbar: true,
        dstat: {
          data: {
            series: [],
            labels: [],
          },
          options: {},
        },

        selectedTable: [],
        questions: [],
        sQuestion: {},
        myQuestion: {},
        questionLabels: {
          icon: 'mdi-unfold-more-vertical',
          type: 'id',
        },
        echart: false,

        mtTitle: '',

      }
    },

    computed: {

      bar () {
        return {
          tooltip: {},
          grid: {
            width: '80%',
            left: '5%',
          },
          xAxis: {
            type: 'category',
            axisLabel: {
            },
            data: this.dstat.data.labels,
          },
          yAxis: [{
            type: 'value',
            minInterval: 1,
          }],
          series: [{
            name: this.$t('common.submissions'),
            type: 'bar',
            data: this.dstat.data.series[0],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          }],
        }
      },

      dataSet () {
        return this.$store.state.ocpu.tableListRules
      },

      // Keep dBlock info open or closed
      dBInfo: {
        get () {
          return this.$store.state.main.dsb.fsettings.dmDstatInfo
        },
        set (value) {
          this.$store.commit('main/setfSetting', { el: 'dmDstatInfo', val: value })
          // console.log(this.$store.state.main.dsb.fsettings)
          this.dBlockSave('fsettings', this.$store.state.main.dsb.fsettings)
        },
      },

    },

    mounted () {
      const main = this.$store.state.main
      // console.log(this.$store.getters['main/dBlockActive'])

      // If this a first visit get dashboard blocks from ocpu
      if (main.dsb.calls === false) {
        // Get Table list from ocpu
        // this.getTableList()

        // Get block info from ocpu dBlocks - dashboarBlocks; dDataset - dashboardDataset; fsettings - Form settings
        // const blocks = ['fsettings', 'dBlocks']
        // blocks.forEach(type => {
        //   // Using function in mixin dashboard.js
        //   this.dBlockGet(type)
        // })
      }
      this.helptoolbar = this.$store.state.main.dsb.fsettings.dmDstatInfo
    },

    methods: {

      dataSetSelected (dataSet) {
        // console.log(dataSet)
        this.pivotData = []
        let myData = this.$store.state.ocpu.tableListRules
        myData = myData.filter(function (item) {
          return item.table.includes(dataSet)
        })
        this.selectedTable = myData
        // console.log(myData[0])
        const filteredQuestions = this.selectedTable[0].column.filter(x => (x.type === 'numeric'))
        this.questions = filteredQuestions
      },

      changeLabel () {
        if (this.questionLabels.type === 'id') {
          this.questionLabels.type = 'label'
          this.questionLabels.icon = 'mdi-unfold-less-vertical'
        } else {
          this.questionLabels.type = 'id'
          this.questionLabels.icon = 'mdi-unfold-more-vertical'
        }
      },

      questionSelected (myQuestion) {
        // console.log(myQuestion)
        this.sQuestion = myQuestion
        const prop = {
          orgid: this.$store.state.main.orgid,
          prjid: this.$store.state.main.ap.prjid,
          formid: this.$store.state.main.afid,
          tblname: this.selectedTable[0].table[0],
          qvar: this.sQuestion.id,
        }
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        // console.log(prop)
        // Get data of descriptive statistics for selected vars of each table
        return new Promise((resolve, reject) => {
          this.$httpOcpu
            .post(`/${ocpuPack}/R/ocvardstat/json`, prop)
            .then(response => {
              // console.log(response.data[this.sQuestion.id])
              this.dstat.data = response.data[this.sQuestion.id]
              // const options = {
              //   axisX: {
              //     showGrid: false,
              //   },
              // }
              // this.dstat.options = options
              this.echart = true
              // console.log(this.dstat)
            })
            .catch(error => {
              // console.log(error)
              reject(error)
            })
        })
      },

      clearPTable () {
        this.dstat.data.labels = null
        this.dstat.data.series[0] = null
        this.dstat.data.stats = null
        this.echart = false
        this.myQuestion = {}
      },

      async mtSave () {
        // clone object
        const configCopy = JSON.parse(JSON.stringify(this.sQuestion))
        const settings = {}
        // generate ID using current date and time in milliseconds
        settings.id = (new Date()).getTime()
        settings.type = 'mtdstat'
        settings.name = this.mtTitle
        settings.tblname = this.selectedTable[0].table
        settings.vars = configCopy.id
        settings.details = configCopy
        // console.log(JSON.stringify(settings))

        const ocpuPack = this.$store.state.ocpu.ocpuPack
        const savemt = () => {
          return new Promise((resolve, reject) => {
            this.$httpOcpu
              .post(`/${ocpuPack}/R/ocmtoolsave/json`, {
                mtdetails: JSON.stringify(settings),
                orgid: this.$store.state.main.orgid,
                prjid: this.$store.state.main.ap.prjid,
                formid: this.$store.state.main.afid,
              })
              .then(response => {
                // console.log(response)
                if (response.status === 201) {
                  // this.getMtoolsList()
                  resolve(response)
                }
              })
              .catch(error => {
                // console.log(error)
                reject(error)
              })
          })
        }
        await savemt()
        this.$refs.mtDStatistics.getmtdstat()
        this.$refs.mtDStatistics.getmtvars()

        // for (let i = 0; i < configCopy.length; i++) {
        //   settings.variables[i].value = this.selectedVar[i]
        // }

        // const settings = JSON.stringify(this.mttype)
        // this.saveMTools(settings)
        // this.dialogMt = false
        // this.dialogBlocks = false
        // this.mttype = ''
        // this.section = ''
        // this.mtTitle = ''
        // this.selectedVar = []

        // col-order="mTable.colOrder"
        // cols="mTable.cols"
        // aggregator-name="mTable.aggregatorName"
        // renderer-name="mTable.rendererName"
        // row-order="mTable.rowOrder"
        // rows="mTable.rows"
        // vals="mTable.vals"

        // console.log(this.selectedTable[0].table[0])
        // console.log(configCopy)
      },

    },
  }
</script>

<style>
  /* .ct-label.ct-label.ct-horizontal.ct-end {
    position: relative;
    justify-content: flex-end;
    text-align: right;
    font-size: 10px !important;
    transform-origin: 100% 0;
    transform: translate(-100%) rotate(-45deg);
    white-space:nowrap;
  } */
  .chart {
    height: 400px;
  }
</style>
