<template>
  <v-row>
    <template v-for="(block, i) in $store.state.main.mtools.dStatistics">
      <v-col
        :key="i"
        cols="12"
        lg="6"
      >
        <v-card
          :key="block.id[0]"
          outlined
          width="100%"
        >
          <v-toolbar
            flat
            color="grey lighten-5"
            dense
          >
            <!-- Drag button -->
            <v-btn
              :color="color"
              class="mr-4"
              fab
              dark
            >
              <v-icon
                class="handle"
              >
                mdi-table
              </v-icon>
            </v-btn>

            <v-toolbar-title :class="`text-h3 ${color}--text text--darken-1`">
              {{ block.name[0] }}
            </v-toolbar-title>
            <v-spacer />
            <!-- Delete button -->
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon
                  color="error"
                  v-on="on"
                  @click="deletemtdstat(block.id[0])"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>{{ $t('common.remove') }}</span>
            </v-tooltip>
          </v-toolbar>
          <div
            v-if="!mStats"
            class="loading"
          >
            <v-progress-linear
              indeterminate
              :color="color"
            />
            ...{{ $t('tooltip.loading') }}
          </div>
          <!-- Statistics -->
          <v-row v-if="mStats">
            <v-col
              cols="12"
              md="4"
              class="mt-0"
            >
              <v-simple-table
                class="ml-1 pt-8"
              >
                <tbody>
                  <tr
                    v-for="(stat, j) in msData[block.vars].stats"
                    :key="j"
                  >
                    <td v-text="stat.type[0]" />
                    <td v-text="stat.value[0]" />
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>

            <v-col
              cols="12"
              md="8"
            >
              <v-chart
                class="chart"
                :option="block.option"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import { use } from 'echarts/core'

  import { CanvasRenderer } from 'echarts/renderers'
  import { BarChart } from 'echarts/charts'
  import {
    GridComponent,
    TooltipComponent,
    LegendComponent,
  } from 'echarts/components'
  import VChart, { THEME_KEY } from 'vue-echarts'
  use([
    CanvasRenderer,
    BarChart,
    GridComponent,
    TooltipComponent,
    LegendComponent,
  ])
  export default {
    name: 'MonitoringStats',

    components: {
      VChart,
    },

    provide: {
      [THEME_KEY]: 'light',
    },

    data () {
      return {
        // settings part
        msData: {},
        dstat: {},
        mStats: false,
        color: 'success',
        loading: false,

        options: {
          tooltip: {},
          grid: {
            width: '80%',
            left: '5%',
          },
          xAxis: {
            type: 'category',
            axisLabel: {
            },
            data: null,
          },
          yAxis: [{
            type: 'value',
            minInterval: 1,
          }],
          series: [{
            name: this.$t('common.submissions'),
            type: 'bar',
            data: null,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          }],
        },

      }
    },

    computed: {},

    created () {
      this.getmtdstat()
    },

    mounted () {},

    methods: {

      async getmtdstat () {
        // console.log('getmtdstat')
        const mtdst = () => {
          const ocpuPack = this.$store.state.ocpu.ocpuPack
          const main = this.$store.state.main
          const prop = {
            orgid: main.orgid,
            prjid: main.ap.prjid,
            formid: main.afid,
            type: 'mtdstat',
          }
          // console.log(prop)
          // console.log(main.mtools.dStatistics.length)
          // if (main.mtools.dStatistics.length === 0) {
          return new Promise((resolve, reject) => {
            this.$httpOcpu
              .post(`/${ocpuPack}/R/ocmtoolget/json`, prop)
              .then(response => {
                // console.log(response)
                if (response.status === 201) {
                  // console.log(response)
                  this.$store.commit('main/setMtool', { type: 'dStatistics', data: response.data })
                  resolve(response)
                }
              })
              .catch(error => {
                // console.log(error)
                reject(error)
              })
          })
          // }
        }
        await mtdst()
        this.getmtvars()
      },

      // async getmtvars1 () {
      //   // console.log('getmtvars')
      //   const ocpuPack = this.$store.state.ocpu.ocpuPack
      //   // function to get variable values
      //   const getocvars = (prop) => {
      //     // console.log(prop)
      //     return new Promise((resolve, reject) => {
      //       // console.log(prop)
      //       this.$httpOcpu
      //         .post(`/${ocpuPack}/R/ocvariables/json`, prop)
      //         .then(response => {
      //           // console.log(response)

      //           if (response.data.length > 0) {
      //             const obj = {}
      //             obj[prop.tblname] = response.data
      //             // console.log(response)
      //             this.monitoringData = Object.assign({}, this.monitoringData, obj)
      //             // console.log(this.monitoringData)
      //             this.mTables = true
      //             resolve(response.data[0])
      //           }
      //         })
      //     })
      //   }

      //   const main = this.$store.state.main
      //   const prop = {
      //     orgid: main.orgid,
      //     prjid: main.ap.prjid,
      //     formid: main.afid,
      //   }

      //   // https://flaviocopes.com/how-to-get-unique-properties-of-object-in-array/
      //   // Get Unique value
      //   const tblnames = [...new Set(main.mtools.dStatistics.map(tbl => tbl.tblname[0]))]
      //   // console.log(tblnames)
      //   // loop tables/section to get vars from ocpu
      //   for (let i = 0; i < tblnames.length; i++) {
      //     prop.tblname = tblnames[i]
      //     // console.log(prop.tblname)
      //     await getocvars(prop)
      //   }
      // },

      async getmtvars () {
        // console.log('getmtvars')
        const main = this.$store.state.main
        const ocpuPack = this.$store.state.ocpu.ocpuPack
        const prop = {
          orgid: main.orgid,
          prjid: main.ap.prjid,
          formid: main.afid,
        }

        const getDstat = prop => {
          return new Promise((resolve, reject) => {
            this.$httpOcpu
              .post(`/${ocpuPack}/R/ocvardstat/json`, prop[0])
              .then(response => {
                // console.log(response.data)
                const optionCopy = JSON.parse(JSON.stringify(this.options))
                optionCopy.xAxis.data = response.data[prop[0].qvar].labels
                optionCopy.series[0].data = response.data[prop[0].qvar].series[0]
                // console.log(response.data[prop[0].qvar].series[0])
                this.$store.commit('main/setMtoolData', { i: prop[1], data: optionCopy })
                this.msData[prop[0].qvar] = response.data[prop[0].qvar]
                this.echart = true
                // console.log(this.msData)
                resolve()
              })
              .catch(error => {
                // console.log(error)
                reject(error)
              })
          })
        }
        if (main.mtools.dStatistics.length > 0) {
          for (let i = 0; i < main.mtools.dStatistics.length; i++) {
            prop.qvar = main.mtools.dStatistics[i].vars[0]
            prop.tblname = main.mtools.dStatistics[i].tblname[0]
            this.$set(this.msData, prop.qvar, {})
            await getDstat([prop, i])
          }
        }
        this.mStats = true
      },

      async deletemtdstat (prop) {
        // console.log(prop)
        const mtdstatdel = () => {
          const ocpuPack = this.$store.state.ocpu.ocpuPack
          const main = this.$store.state.main
          return new Promise((resolve, reject) => {
            this.$httpOcpu
              .post(`/${ocpuPack}/R/ocmtdel/json`, {
                mtool: { id: prop, type: 'mtdstat' },
                orgid: main.orgid,
                prjid: main.ap.prjid,
                formid: main.afid,
              })
              .then(response => {
                // console.log(response)
                if (response.status === 201) {
                  this.getmtdstat()
                  resolve(response)
                }
              })
              .catch(error => {
                // console.log(error)
                reject(error)
              })
          })
        }
        await mtdstatdel()
        this.getmtvars()
      },

    },
  }
</script>
